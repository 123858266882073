import { useEffect, useState } from 'react';
import DropdownForForm from '../../../../components/DropDownForFormComponent';
import { AdditionalCharacteristicsList } from './AdditionalCharacteristicsList/AdditionalCharacteristicsList';
import { PhotoList } from './PhotoList/PhotoList';
import styles from './RequiredCharacteristicsForm.module.css';
import { RequiredCharacteristicsList } from './RequiredCharacteristicsList/RequiredCharacteristicsList';
import { VariationsCheboxPanel } from './VariationCheckboxPanel/VariationsCheckboxPanel';
import CustomCheckBox from '../../../../components/CustomCheckBoxComponent';

export const RequiredCharacteristicsForm = ({ handleProductDataFieldChange, productData, characteristicOptions, setProductData, isDigital, formErrors, setFormErrors }) => {

    const [gabaritType, setGabaritType] = useState(0);
    const [gabaritIndex, setGabaritIndex] = useState(-1);

    const setPhotosList = (photos) => {
        setProductData(
            prevState => {
                return {
                    ...prevState,
                    base_combo: { ...prevState.base_combo, photos: photos },
                }
            }
        )
    };

    const setIsVariative = (state) => {
        setProductData(prevState => { return { ...prevState, is_variative: state } })
    };

    const changeReqFields = (name, value) => {
        handleProductDataFieldChange(name, value);
        const updatedErrors = { ...formErrors };
        delete updatedErrors[name.split(".")[1]];
        setFormErrors(updatedErrors);
    };

    const gabaritsOptions = [
        { id: 0, name: '15 x 15 x 10см', array: [15, 15, 10] },
        { id: 1, name: '20 x 20 x 20см', array: [20, 20, 20] },
        { id: 2, name: '35 x 20 x 20см', array: [35, 20, 20] },
        { id: 3, name: '35 x 30 x 25см', array: [35, 30, 25] },
        { id: 4, name: '50 x 40 x 35см', array: [50, 40, 35] },
        { id: 5, name: '80 x 15 x 20см', array: [80, 15, 20] },
    ];

    function findGabaritId(array) {
        const foundOption = gabaritsOptions.find(option => {
            return JSON.stringify(option.array) === JSON.stringify(array);
        });

        return foundOption ? foundOption.id : -1;
    }

    function changeGabarits(index) {
        const gabArr = gabaritsOptions[index].array;
        setGabaritIndex(index);
        setProductData(
            prevState => {
                return {
                    ...prevState,
                    base_combo: {
                        ...prevState.base_combo,
                        height: gabArr[0],
                        width: gabArr[1],
                        depth: gabArr[2]
                    },
                }
            }
        );
    }

    useEffect(() => {
        const gabArr = [productData.base_combo.height, productData.base_combo.width, productData.base_combo.depth];
        const gabIndex = findGabaritId(gabArr);
        setGabaritIndex(gabIndex);

        if (gabIndex === -1) {
            setGabaritType(prev => 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // productData.base_combo.height, productData.base_combo.width, productData.base_combo.depth

    return (
        <div className={styles.wrapper}>
            <div className={styles.column}>
                {
                    !isDigital &&
                    <>
                        <div className={styles.formCharacteristicsTitle}>Обязательные характеристики</div>
                        <div className={styles.formGroup}>
                            <div className={styles.gabaritsBtns}>
                                <button type="button" className={gabaritType === 0 && styles.activeBtn} onClick={() => { setGabaritType(0); }}>Без габаритов</button>
                                <button type="button" className={gabaritType === 1 && styles.activeBtn} onClick={() => { setGabaritType(1); }}>Стандартные размеры</button>
                                <button type="button" className={gabaritType === 2 && styles.activeBtn} onClick={() => { setGabaritType(2); }}>Другое</button>
                            </div>
                        </div>

                        {gabaritType === 1 &&
                            <div className={styles.standartGabarits}>
                                <div className={`${styles.formGroup} ${styles.gabaritsList}`}>
                                    <span>Выберите размер упаковки</span>
                                    <DropdownForForm options={gabaritsOptions} placeHolder="Выберите размер упаковки" initialChoice={gabaritIndex} setChoice={(value) => { changeGabarits(value); }} />
                                </div>
                                <div className={styles.formGroup}>
                                    <span>Вес, гр</span>
                                    <input
                                        type="text"
                                        min="0"
                                        name="base_combo.weight"
                                        value={productData.base_combo.weight}
                                        onChange={({ target }) => changeReqFields(target.name, target.value)}
                                        placeholder="0"
                                        required
                                    />
                                    {formErrors.weight && <span className={styles.fieldError}>{formErrors?.weight}</span>}
                                </div>
                            </div>
                        }

                        {gabaritType === 2 && <RequiredCharacteristicsList productData={productData} onChange={({ target }) => changeReqFields(target.name, target.value)} formErrors={formErrors} />}
                    </>
                }

                <div className={styles.formCharacteristicsTitle}>Дополнительные характеристики</div>
                <AdditionalCharacteristicsList productData={productData} characteristicOptions={characteristicOptions} setProductData={setProductData} />
                {!productData.is_variative ? (
                    <div className={styles.extraInput}>
                        <div>
                            <div className={styles.formGroup}>
                                <div className={styles.checkBoxField}>
                                    <CustomCheckBox outId='alwaysInStock' checked={productData.base_combo.always_in_stock} onChange={() => { if (productData.base_combo.stock === 0) { handleProductDataFieldChange('base_combo.stock', 1) } handleProductDataFieldChange('base_combo.always_in_stock', !productData.base_combo.always_in_stock); }} />
                                    <span><label htmlFor={'alwaysInStock'}>Всегда в наличии</label></span>
                                </div>
                            </div>

                            {!productData.base_combo.always_in_stock && <div className={styles.formGroup}>
                                <span>Количество на складе</span>
                                <input
                                    type="text"
                                    name="base_combo.stock"
                                    value={productData.base_combo.stock}
                                    placeholder="Введите количество на складе"
                                    required
                                    onChange={({ target }) => handleProductDataFieldChange(target.name, target.value)}
                                />
                            </div>}
                        </div>
                        <div className={styles.formGroup}>
                            <span>Статус товара</span>
                            <DropdownForForm options={[{ id: '0', name: 'Опубликован' }, { id: '1', name: 'Черновик' }]} initialChoice={productData.state ? '0' : '1'} setChoice={(choiceID) => { handleProductDataFieldChange('state', choiceID === '0') }} placeHolder="Опубликован/Черновик" />
                        </div>
                    </div>
                ) : null}
                {productData.id !== 'new' && <VariationsCheboxPanel isVisible={productData.is_variative} setIsVisible={setIsVariative} />}
            </div>
            <div className={styles.column}>
                <div className={styles.photosTitle}>Фотографии товара</div>
                <PhotoList photos={productData.base_combo.photos} productData={productData} setProductData={setProductData} setPhotos={setPhotosList} />
            </div>
        </div >
    );
}