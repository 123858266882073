import axios from "axios";

const baseUsersAPIURL = "https://user-vault.thebotique.ru/api/v1";
const baseCartAPIURL = "https://api-admin.thebotique.ru/api/v1/cart";
const baseAPIURL = "https://api-admin.thebotique.ru/api/v1";
const baseMailingAPIURL = "https://mailing.thebotique.ru/api/v1";

const api = {
    getCustomers: async () => {
        try {
            const response = await axios.get(`${baseUsersAPIURL}/stores/${localStorage.getItem('current_store_id')}/users`, {
                headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    getCarts: async () => {
        try {
            const response = await axios.get(`${baseCartAPIURL}/store/${localStorage.getItem('current_store_id')}/carts`, {
                params: { token: localStorage.getItem('_signiture_for_store_data') },
                headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    getShortCuts: async () => {
        try {
            const response = await axios.get(`${baseAPIURL}/marketing-shortcut/`, {
                headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    getHistory: async (page) => {
        try {
            const response = await axios.get(`${baseMailingAPIURL}/stores/${localStorage.getItem('current_store_id')}/mailings/search`, {
                params: { size: 4, page: page },
                headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
    sendMailing: async (text, photo, users) => {
        try {
            return await axios.post(`${baseMailingAPIURL}/mailings`, {
                store_id: localStorage.getItem('current_store_id'),
                text: text,
                photo: photo,
                parse_mode: "HTML",
                users: users,
                token: localStorage.getItem('_signiture_for_store_data')
            }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json"
                }
            });
        } catch (e) {
            throw e;
        }
    },
}

export default api;