import moment from 'moment';
import React from "react";
import { useNavigate } from 'react-router-dom';
import FormatNumbers from "../../../components/FormatNumbersComponent";
import styles from "./OrderItemMobileComponent.module.css";

function OrderItemMobile({ order }) {

    const nav = useNavigate();

    const getSufType = (number) => {
        if (number === 1) {
            return 'товар';
        } else if (number > 1 & number <= 4) {
            return 'товара';
        } else if ((number > 4 & number < 15) | number === 0) {
            return 'товаров';
        } else {
            return getSufType(number % 10);
        }
    };

    const getDeliveryType = (code) => {
        if (code === 1) {
            return "Самовывоз";
        } else if (code === 3) {
            return "Курьерская";
        } else if (code === 2) {
            return "Boxberry";
        } else if (code === 4) {
            return "Цифровой товар / услуга";
        }
    };

    const getOrderItemsInfo = (options) => {
        if (options) {
            const count = options.map(el => { return el.quantity }).reduce((a, b) => a + b);
            return `${count} ${getSufType(count)}`;
        } else {
            return 0;
        }
    };

    return (
        <div className={styles.item}>
            <div className={styles.option}>
                <span>Номер заказа:</span>
                <span> #{order.order_id}</span>
            </div>
            <div className={styles.option}>
                <span>Дата заказа:</span>
                <span>{moment(order.created_at).format('DD.MM.YYYY HH:mm:ss').toLocaleString('default', { timeZoneName: 'short' })}</span>
            </div>
            <div className={styles.option}>
                <span>Вид доставки:</span>
                <span>{getDeliveryType(order.delivery.type)}</span>
            </div>
            <div className={styles.option}>
                <span>Клиент:</span>
                <span>{order.delivery.client_name}</span>
            </div>

            <div className={styles.option}>
                <span>Cтатус:</span>
                <span>{order.state.id}</span>
            </div>
            <div className={styles.info}>
                <span>{getOrderItemsInfo(order.options)}</span>
                <span><FormatNumbers number={`${order.total.amount / 100} ₽`} /> </span>
            </div>
            <button onClick={() => { nav(`/order/${order.order_id}`) }}>Перейти</button>
        </div>
    )
}
OrderItemMobile.defaultProps = {
    order: { date: "23.07.2023 16:40:01", number: "#f94664e4-9527-4746-815b-da4fa2e04887", shipping: "Самовывоз", status: "ожидает подтверждения", countOfProduct: 5, sum: 1000 }
};
export default OrderItemMobile;