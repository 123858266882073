import moment from 'moment';
import React from "react";
import { useNavigate } from 'react-router-dom';
import FormatNumbers from "../../../components/FormatNumbersComponent";
import styles from "./OrderItemComponent.module.css";

function OrderItemComponent({ order }) {

    // console.log(order);

    const nav = useNavigate();

    const getSufType = (number) => {
        if (number === 1) {
            return 'товар';
        } else if (number > 1 & number <= 4) {
            return 'товара';
        } else if ((number > 4 & number < 15) | number === 0) {
            return 'товаров';
        } else {
            return getSufType(number % 10);
        }
    };

    const getDeliveryType = (code) => {
        if (code === 1) {
            return "Самовывоз";
        } else if (code === 3) {
            return "Курьерская";
        } else if (code === 2) {
            return "Boxberry";
        } else if (code === 4) {
            return "Цифровой товар / услуга";
        }
    };

    const getOrderItemsInfo = (options) => {
        if (options) {
            const count = options.map(el => { return el.quantity }).reduce((a, b) => a + b);
            return `${count} ${getSufType(count)}`;
        } else {
            return 0;
        }
    };

    return (
        <div className={styles.item} onClick={() => { nav(`/order/${order.order_id}`) }}>
            <div>{moment(order.created_at).format('DD.MM.YYYY HH:mm:ss').toLocaleString('default', { timeZoneName: 'short' })}</div>
            <div>#{order.order_id}</div>
            <div style={{ textAlign: "center" }}>{order.delivery.client_name.length >= 20 ? order.delivery.client_name.slice(0, 10) + "..." : order.delivery.client_name}</div>
            <div>{getDeliveryType(order.delivery.type)}</div>
            <div>{order.state.id}</div>
            <div>{getOrderItemsInfo(order.options)}</div>
            <div><FormatNumbers number={`${order.total.amount / 100} ₽`} /></div>
        </div>
    )
}
OrderItemComponent.defaultProps = {
    order: { date: "23.07.2023 16:40:01", number: "#f94664e4-9527-4746-815b-da4fa2e04887", shipping: "Самовывоз", status: "ожидает подтверждения", countOfProduct: 5, sum: 1000 }
};
export default OrderItemComponent;