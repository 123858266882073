import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomCheckBox from "../../components/CustomCheckBoxComponent";
import Footer from "../../components/FooterComponent_v2";
import Header from "../../components/HeaderComponent_v2";
import { error, success } from "../../components/notification";
import infoImg from '../../assets/info-icon.svg';
import styles from './StoreSettingsPage.module.css';
import botSettingsAPI from "./api/api";

export default function StoreSettingsPage() {
    const { storeID } = useParams();
    const [greetingFlag, setGreetingFlag] = useState(false);
    const [supportFlag, setSupportFlag] = useState(true);

    const [firstNameSecondFlag, setFirstNameSecondFlag] = useState(true);
    const [phoneNumberFlag, setPhoneNumberFlag] = useState(true);
    const [emailFlag, setEmailFlag] = useState(true);
    const [INNFlag, setINNFlag] = useState(false);
    const [commentFlag, setCommentFlag] = useState(false);
    const [dateFlag, setDateFlag] = useState(false);
    const [dateFieldName, setDateFieldName] = useState('    ');

    const [greetingMessage, setGreetingMessage] = useState('');
    const [paymentMessage, setPaymentMessage] = useState('');
    const [supportLink, setSupportLink] = useState('');
    const [minOrderPrice, setMinOrderPrice] = useState(0);
    const [freeDeliveryPrice, setFreeDeliveryPrice] = useState(0);
    const [freeDeliveryFlag, setFreeDeliveryFlag] = useState(false);

    const [showFreeDeliveryInfo, setShowFreeDeliveryInfo] = useState(false);

    useEffect(() => {
        botSettingsAPI.getBotSettings(storeID).then((r) => {
            setGreetingFlag(r?.data?.is_custom_greeting);
            setSupportFlag(r?.data?.is_custom_support);
            setGreetingMessage(r?.data?.custom_greeting);
            setSupportLink(r?.data?.custom_support);
        });

        botSettingsAPI.getOrderFormSettings(storeID).then(({ data }) => {
            setFirstNameSecondFlag(data?.name);
            setPhoneNumberFlag(data?.phone);
            setINNFlag(data?.inn);
            setCommentFlag(data?.comment);
            setEmailFlag(data?.email);
            setDateFlag(data?.custom_date);
            setDateFieldName(data?.custom_date_title);
        });

        botSettingsAPI.getMinOrderPrice(storeID).then((r) => {
            setMinOrderPrice(r?.data?.min_order_price);
        });

        botSettingsAPI.getPaymentMessage(storeID).then((r) => {
            setPaymentMessage(r?.data?.payment_message);
        });

        botSettingsAPI.getFreeDeliveryPrice(storeID).then((r) => {
            if (r?.data?.free_delivery_price) {
                setFreeDeliveryFlag(true);
                setFreeDeliveryPrice(r.data.free_delivery_price);
            } else {
                setFreeDeliveryFlag(false);
            }
        })

    }, [storeID]);


    const handleSave = () => {
        if (greetingMessage.length > 400) {
            error('Длина приветственного сообщения должна быть менее 400 символов');
            return;
        }

        if (supportLink.length > 256) {
            error('Длина ссылки на поддержку должна быть менее 256 символов');
            return;
        }

        botSettingsAPI.updateBotSettings(storeID, {
            is_custom_greeting: greetingFlag,
            is_custom_support: supportFlag,
            custom_greeting: greetingMessage,
            custom_support: supportLink
        }).then(() => success('Настройки бота сохранены'));

        botSettingsAPI.updateMinOrderPrice(storeID, {
            min_order_price: minOrderPrice,
        }).then(() => success('Минимальная стоимость заказа обновлена'));

        botSettingsAPI.updatePaymentMessage(storeID, { payment_message: paymentMessage }).then(() => success('Сообщение о способе оплаты обновлено'));

        if (freeDeliveryFlag) {
            botSettingsAPI.updateFreeDeliveryPrice(storeID, { free_delivery_price: freeDeliveryPrice }).then(() => success('Минимальная стоимость для бесплатной доставки обновлена'));
        } else {
            botSettingsAPI.updateFreeDeliveryPrice(storeID, { free_delivery_price: null }).then(() => success('Минимальная заказа для бестплатной доставки обновлена'));
        }

        if (dateFlag) {
            if (dateFieldName.length === 0) {
                error('Название поля "Дата и время" не заполненно.');
                return;
            }
        }

        botSettingsAPI.updateOrderFormSettings(storeID, {
            name: firstNameSecondFlag,
            phone: phoneNumberFlag,
            email: emailFlag,
            inn: INNFlag,
            comment: commentFlag,
            custom_date: dateFlag,
            custom_date_title: dateFieldName
        }).then(() => success('Настройки полей формы заказа сохранены'));
    };

    return (
        <>
            <Header title={"Настройка магазина"} />
            <form className={styles.container}>
                <div className={styles.containerTitle}><span>НАСТРОЙКА ФОРМЫ ОФОРМЛЕНИЯ ЗАКАЗА</span></div>
                <div className={styles.formField}>
                    <div><span>Позволяет настроить процесс оформления заказа для удобства пользователей.</span></div>
                    <div className={styles.checkboxArea}>
                        <CustomCheckBox outId='firstNameSecondName' checked={true} />
                        <span><label htmlFor={'firstNameSecondName'}>ФИО</label></span>
                    </div>
                    <div className={styles.checkboxArea}>
                        <CustomCheckBox outId='phoneNumber' checked={true} />
                        <span><label htmlFor={'phoneNumber'}>Телефон</label></span>
                    </div>
                    <div className={styles.checkboxArea}>
                        <CustomCheckBox outId='email' checked={emailFlag} onChange={() => { setEmailFlag(prev => !prev); }} />
                        <span><label htmlFor={'email'}>Email</label></span>
                    </div>
                    <div className={styles.checkboxArea}>
                        <CustomCheckBox outId='INN' checked={INNFlag} onChange={() => { setINNFlag(prev => !prev); }} />
                        <span><label htmlFor={'INN'}>ИНН (для организаций)</label></span>
                    </div>
                    <div className={styles.checkboxArea}>
                        <CustomCheckBox outId='comment' checked={commentFlag} onChange={() => { setCommentFlag(prev => !prev); }} />
                        <span><label htmlFor={'comment'}>Комментарий</label></span>
                    </div>
                    <div className={styles.checkboxArea}>
                        <CustomCheckBox outId='date' checked={dateFlag} onChange={() => { setDateFlag(prev => !prev); }} />
                        <div className={styles.dateField}>
                            <span><label htmlFor={'date'}>Поле "Выбор даты"</label></span>
                            {dateFlag && <input type="text" value={dateFieldName} onChange={({ target }) => { if (target.value.length < 32) setDateFieldName(target.value); }} required placeholder="Введите название поля"></input>}
                        </div>
                    </div>
                </div>

                <div className={styles.containerTitle}><span>Настройки бота</span></div>
                <div className={styles.formField}>
                    <div><span>Приветственное сообщение при запуске бота</span></div>
                    {greetingFlag && <textarea value={greetingMessage} onChange={({ target }) => { setGreetingMessage(target.value); }} required></textarea>}
                    <div className={styles.checkboxArea}>
                        <CustomCheckBox outId='greet' checked={!greetingFlag} onChange={() => { setGreetingFlag(prev => !prev); }} />
                        <span><label htmlFor={'greet'}>Стандартное приветствие</label></span>
                    </div>
                </div>
                <div className={styles.formField}>
                    <div><span>Ссылка на поддержку</span></div>
                    {supportFlag && <input type="text" value={supportLink} onChange={({ target }) => { setSupportLink(target.value); }} required></input>}
                    <div className={styles.checkboxArea}>
                        <CustomCheckBox outId='support' checked={!supportFlag} onChange={() => { setSupportFlag(prev => !prev); }} />
                        <span><label htmlFor={'support'}>Поддержка через сервис Ботик</label></span>
                    </div>
                </div>

                <div className={styles.containerTitle}><span>Сообщение о способе оплаты</span></div>
                <div className={styles.formField}>
                    <textarea value={paymentMessage} onChange={({ target }) => { setPaymentMessage(target.value); }} required></textarea>
                </div>

                <div className={styles.containerTitle}><span>Минимальная стоимость заказа</span></div>
                <div className={`${styles.formField} ${styles.minOrderPriceField}`}>
                    <input type="text" value={minOrderPrice} onChange={({ target }) => { if (isNaN(target.value)) { return } else { setMinOrderPrice(Number(target.value)); } }} required></input>
                    <span>₽</span>
                </div>

                <div className={styles.containerTitle}>
                    <span>Cтоимость заказа для бесплатной доставки</span>
                    <span onMouseEnter={() => setShowFreeDeliveryInfo(true)} onMouseLeave={() => setShowFreeDeliveryInfo(false)}>
                        <img className={styles.infoImg} src={infoImg} alt="" />
                        {showFreeDeliveryInfo && (
                            <div className={styles.notificationBlock}>
                                <p>При указанной сумме заказа в корзине стоимость доставки будет бесплатной</p>
                            </div>
                        )}
                    </span>
                </div>
                <div className={styles.formField}>
                    <div className={styles.checkboxArea}>
                        <CustomCheckBox outId='freeDelivery' checked={freeDeliveryFlag} onChange={() => { setFreeDeliveryFlag(prev => !prev); }} />
                        <span><label htmlFor={'freeDelivery'}>Бесплатная доставка</label></span>
                    </div>
                    {freeDeliveryFlag && <div className={`${styles.formField} ${styles.minOrderPriceField}`}>
                        <span>При заказе от</span>
                        <input type="text" value={freeDeliveryPrice} onChange={({ target }) => { if (isNaN(target.value)) { return } else { setFreeDeliveryPrice(Number(target.value)); } }} required></input>
                        <span>₽</span>
                    </div>}
                </div>
                <button className={styles.saveBtn} type="button" onClick={handleSave}>Сохранить</button>
            </form >
            <Footer />
        </>
    )
}