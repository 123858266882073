import moment from "moment"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import api from "../../../api/api"
import arrow from '../../../assets/Vector.png'
import FormatNumbers from "../../../components/FormatNumbersComponent"
import StarRating from "../../../components/StarsComponent"
import place from "../../../assets/icon-2.png"
import MobileOrderItem from "./MobileOrderItemComponent"
// import orderBackImg from "../../../assets/orderBack.png"
import time from "../../../assets/icon-3.png"
import card from "../../../assets/icon.png"
import sortArrow from "../../../assets/sort1.png"
import star from "../../../assets/Star 1.png";
import PopupCustomComponent from "../../../components/PopupCustomComponent"
import styles from "./OrderInformationComponent.module.css"
import OrderItemInfoComponent from "./OrderItemInfoComponent"

function OrderInformationComponent({ order, orderReview }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [basicStatus, setBasicStatus] = useState(["Оформлен", "Ожидает оплаты", "Оплачен", "Подтвержден", "Завершен", "Отменен"]);

  const getStatusClass = (index, targetStatus) => {
    if (basicStatus[index] === 'Отменен') {
      if (index === targetStatus) {
        return styles.itemCancelStatus
      } else if (index < targetStatus) {
        return styles.itemActiveCancelStatus
      } else {
        return styles.itemCancelStatus
      }
    } else {
      if (index === targetStatus) {
        return styles.itemNoramlStatus
      } else if (index < targetStatus) {
        return styles.itemActiveStatus
      } else {
        return styles.itemNonActive
      }
    }

  };

  const getDeliveryType = (code) => {
    if (code === 1) {
      return "Самовывоз";
    } else if (code === 3) {
      return "Курьерская";
    } else if (code === 2) {
      return "Boxberry";
    } else if (code === 4) {
      return "Цифровой товар / услуга";
    }
  };

  const [targetStatusIndex, setTargetStatus] = useState(null);

  const handlerChangeStatus = (status) => {
    if (targetStatusIndex === 3) {
      api.confirmStatus(order.order_id).then(r => {
        if (r.status === 200) {
          setTargetStatus(4);
        }
      })
    } else if (targetStatusIndex === 4) {
      api.completeStatus(order.order_id).then(r => {
        if (r.status === 200) {
          setTargetStatus(5);
        }
      })
    }
  };

  useEffect(() => {
    document.body.classList.add(styles.bodyRuleDisappear);
    return () => {
      document.body.classList.remove(styles.bodyRuleDisappear);
    };
  }, []);


  useEffect(() => {
    if (order.pay_type === 1) {
      setBasicStatus(["Оформлен", "Ожидает оплаты", "Без оплаты", "Подтвержден", "Завершен", "Отменен"]);
    }
    if (order.state) {
      if (order.state.id === 'Новый') {
        setTargetStatus(0);
      } else if (order.state.id === 'Оформлен') {
        setTargetStatus(3);
      } else if (order.state.id === 'Подтвержден') {
        setTargetStatus(4);
      } else if (order.state.id === 'Завершен') {
        setTargetStatus(5);
      }
    }
  }, [order]);

  if (order.total !== undefined) {
    return (
      <div className={styles.container}>
        <Link to={"/orders"} className={styles.backBtn}>
          <img src={arrow} alt="" />
          <span>Вернуться ко всем заказам</span>
        </Link>
        <h1 className={styles.title}>Информация по заказу</h1>
        <div className={styles.info}>Заказ № {order.order_id}</div>
        <div className={styles.info}>
          <span style={{}}> <span style={{ color: "black" }}>от</span> {moment(order.created_at).format('DD.MM.YYYY HH:mm:ss')}</span>{" "}
          <span>({order.state.id})</span>
        </div>
        <div className={styles.orderPayment}>
          <div className={styles.orderPaymentTitle}>
            <img src={card} alt="" />
            <span>Способ оплаты</span>
          </div>
          <div className={styles.orderPaymentInfo}>
            <span>{order?.pay_type === 2 ? "Картой" : "Наличными"}</span>
            <FormatNumbers number={order.total.amount / 100 + " ₽"} />
          </div>
        </div>
        <div className={styles.orderStorage}>
          <div className={styles.orderDescription}>

            <div className={styles.orderShipping}>
              <div className={styles.orderShippingTitle}>
                <img src={place} alt="" />
                <span>Способ получения</span>
              </div>
              <div className={styles.orderShippingText}>
                <div className={styles.orderShippingTextColumn}>
                  <div className={styles.orderShippingBlock}>
                    <span>Получатель:</span>
                    <div>{order.delivery.client_name}</div>
                    {order.delivery?.tg_username && <Link to={`https://t.me/${order.delivery.tg_username}`}>Написать в Telegram</Link>}
                    <div>+{order.delivery.phone}</div>
                    <div>{order.delivery.email}</div>
                    {order.delivery?.inn && <div>ИНН: {order.delivery.inn}</div>}
                  </div>
                  <div className={styles.orderShippingBlock}>
                    <span>Тип доставки:</span>
                    {getDeliveryType(order.delivery.type)}
                    {order.delivery.type === 2 && <p>Завершите работу с заказом в <Link to="https://account.boxberry.ru">личном кабинете Boxberry</Link>.</p>}
                  </div>
                </div>
                <div className={styles.orderShippingTextColumn}>
                  <div className={styles.orderShippingBlock}>
                    <span>Адрес доставки  (или самовывоза):</span>
                    <p>{order.delivery.address}</p>
                  </div>
                  <div className={styles.orderShippingBlock}>
                    <span>Комментарий к заказу:</span>
                    {<p>{(order.delivery.rent_start && new Date(order?.delivery?.rent_start).getFullYear() > 1) ? `Дата и время: ${moment(order.delivery.rent_start).format('DD.MM.YYYY')}` : ""}</p>}
                    {order.delivery.comment ? order.delivery.comment : "Нет комментария клиента"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.orderStorageContainer}>
            <div className={styles.emergancy}>
              <span className={styles.emergancyText}>Внимание!</span> Не забывайте менять статус заказа, чтобы отслеживать доставленные и не доставленные заказы.
            </div>
            <div className={styles.orderBlocks}>
              <div className={styles.orderRating}>
                <div className={styles.orderRatingTitle}>
                  <img src={star} alt="" />
                  <span>Оценка заказа</span>
                </div>
                {orderReview?.score ?
                  <>
                    <StarRating rating={orderReview.score} />
                    <div className={styles.orderRatingComment}>
                      <span>Комментарий:</span>
                      <p>Отзыв получен {moment(orderReview.created_at).format('DD.MM.YYYY HH:mm')}</p>
                    </div>
                  </> :
                  <>
                    <div className={styles.orderRatingComment}>
                      <p>Клиент пока не оценил заказ. Запрос на оценку заказа отправляется при статусе "Завершен"</p>
                    </div>
                  </>
                }
              </div>
              <div className={styles.orderStatus}>
                <div className={styles.orderPaymentTitle}>
                  <img src={time} alt="" />
                  <span>Статус заказа</span>
                </div>
                <div className={styles.orderStatusInfo}>
                  {basicStatus.map((item, index) => {
                    if ((item === "Подтвержден" | item === "Завершен") && getStatusClass(index, targetStatusIndex) === styles.itemNoramlStatus) {
                      return (
                        <div key={index} className={getStatusClass(index, targetStatusIndex)}>
                          <span>{item}</span>
                          <PopupCustomComponent triggerEl={<div className={styles.point}></div>} title={"Вы действительно хотите изменить статус?"} text={""} handler={handlerChangeStatus} paramsHandler={item} />
                        </div>)
                    }
                    return (<div
                      key={index}
                      className={getStatusClass(index, targetStatusIndex)}
                    >
                      <span>{item}</span>
                      <div className={styles.point}></div>
                    </div>)
                  })}
                </div>
              </div>
            </div>
            <div className={styles.orderBlocks}>
              {/* <div className={styles.orderBackBlock}>
                <div className={styles.orderBackBlockTitle}>
                  <img src={orderBackImg} alt="" />
                  <span>Данные по возврату</span>
                </div>
                <div className={styles.orderRatingComment}>
                  <span>Комментарий:</span>
                  <p>Все понравилось. Размер подошел. Хотелось бы быстрей доставку.</p>
                </div>
              </div> */}
              <div className={styles.cost}>
                <div className={styles.costItem}>
                  <span>Сумма заказа</span>
                  <FormatNumbers number={order.total.amount / 100 + " ₽"} />
                </div>
                <div className={styles.costItem}>
                  <span>Стоимость доставки</span>
                  <FormatNumbers number={order.total.delivery_cost / 100 + " ₽"} />
                </div>
                <div className={styles.costItem}>
                  <span>Стоимость товаров</span>
                  <FormatNumbers number={order.total.goods / 100 + " ₽"} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ordersItems}>

          <div className={styles.tableContainer}>
            <div className={styles.table}>
              <div><span>Наименование</span><img src={sortArrow} alt="" /></div>
              <div><span>Статус</span><img src={sortArrow} alt="" /></div>
              <div><span>Кол-во</span><img src={sortArrow} alt="" /></div>
              <div><span>Цена за штуку</span><img src={sortArrow} alt="" /></div>
              <div><span>Скидка</span><img src={sortArrow} alt="" /></div>
              <div><span>Итого</span><img src={sortArrow} alt="" /></div>
            </div>
            {order.options && order.options.map((obj, idx) => (
              <OrderItemInfoComponent key={idx} position={obj} />
            ))}
          </div>
        </div>
        <div className={styles.mobileOrderContainer}>
          <h4 className={styles.mobileOrderContainerTitle}>Товары</h4>
          {order.options && order.options.map((obj, idx) => (
            <MobileOrderItem key={idx} position={obj} />
          ))}
        </div>
      </div>
    )
  }
}

export default OrderInformationComponent;
