import styles from "./ProductsGroupPage.module.css";

import React, { useEffect, useState } from "react";
import Footer from "../../components/FooterComponent_v2";
import Header from "../../components/HeaderComponent_v2";
import Loading from "../../components/Loading";
import { error, success } from "../../components/notification";
import api from "./api/api";
import AddGroupButton from "./components/AddGroupButton";
import GroupNameModal from "./components/GroupNameModal";
import ProductGroup from "./components/ProductGroup";
import ProductsChoiceModal from "./components/ProductsChoiceModal";

export default function ProductsGroupPage() {
    const [isProductsModalOpen, setIsProductsModalOpen] = useState(false);
    const [isNameModalOpen, setIsNameModalOpen] = useState(false);
    const [addProductsToGroupFlag, setAddProductsToGroupFlag] = useState(false);
    const [currentGroupID, setCurrentGroupID] = useState('');
    const [currentDiscount, setCurrentDiscount] = useState('');
    const [currentProducts, setCurrentProducts] = useState([]);
    const [currentLabels, setCurrentLabels] = useState([]);
    const [newGroupName, setNewGroupName] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [groups, setGroups] = useState([]);

    const [isProductsLoading, setIsProductsLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isToggleLoading, setIsToggleLoading] = useState('');

    const [labelOptions, setLabelOptions] = useState([]);
    // [
    //     {
    //         name: 'Стиль 1',
    //         label_color: '#f0f8ff',
    //         border_color: '#007bff',
    //         border_style: 'solid',
    //         border_width: '2px',
    //         text_color: '#007bff',
    //     },
    //     {
    //         name: 'Стиль 2',
    //         label_color: '#ffe4e1',
    //         border_color: '#ff4500',
    //         border_style: 'dashed',
    //         border_width: '3px',
    //         text_color: '#ff4500',
    //     },
    //     {
    //         name: 'Стиль 3',
    //         label_color: '#98fb98',
    //         border_color: '#006400',
    //         border_style: 'dotted',
    //         border_width: '1px',
    //         text_color: '#006400',
    //     },
    //     {
    //         name: 'Стиль 4',
    //         label_color: '#ffffff',
    //         border_color: '#000000',
    //         border_style: 'solid',
    //         border_width: '2px',
    //         text_color: '#000000',
    //     },
    // ];

    const closeNameModal = () => {
        setIsNameModalOpen(false);
    };
    const closeProductsModal = () => {
        setIsProductsModalOpen(false);
    };

    const continueNameModal = () => {
        setIsProductsLoading(true);
        api.getProducts().then(r => {
            if (r?.data) {
                setIsProductsLoading(false);
                setProducts(r.data);
                setIsNameModalOpen(false);
                if (newGroupName !== '') {
                    setIsProductsModalOpen(true);
                }
            } else {
                error('Произошла ошибка. Повторите позже.')
            }
        });
    };

    const createProductsGroup = (products) => {
        api.createGroup({
            store: localStorage.getItem("current_store_id"),
            name: newGroupName,
            products: products,
            labels: []

        }).then(r => {
            if (r?.data) {
                success('Группа добавлена');
                setIsProductsModalOpen(false);
                setGroups(prev => [...prev, {
                    id: r.data.id,
                    name: newGroupName,
                    products_number: products.length
                }]);
                setNewGroupName('');
                setSelectedProducts([]);
            } else {
                error('Произошла ошибка. Попробуйте снова');
            }
        });
    };

    const updateProductsInGroup = () => {
        api.addProductsToGroup(currentGroupID, { products: selectedProducts }).then(r => {
            if (r?.data) {
                setSelectedProducts([]);
                setAddProductsToGroupFlag(false);
                setIsProductsModalOpen(false);
                setIsLoading(true);
                api.viewGroup(currentGroupID).then(r => {
                    setIsLoading(false);
                    if (r?.data) {
                        setCurrentProducts(r.data?.products ? r.data.products : []);
                        setCurrentLabels(r.data?.labels ? r.data.labels : [])
                    } else {
                        error('Произошла ошибка.');
                    }
                });
            } else {
                error('Произошла ошибка при добавлении товара к группе.');
            }
        })
    };

    const deleteProductsGroup = (groupID) => {
        api.deleteGroup(groupID).then(r => {
            if (r?.status === 204) {
                const currentGroups = groups.filter(group => {
                    if (group.id !== groupID) return true;
                });
                setGroups(currentGroups);
                success('Группа удалена');
            }
        })
    };

    const openProductsModal = (groupID, productsInGroup) => {
        setIsLoading(true);
        api.getProducts().then(r => {
            if (r?.data) {
                setIsLoading(false);
                const idsToRemove = new Set(productsInGroup.map(product => product.id));
                const updatedProducts = r.data.filter(product => !idsToRemove.has(product.id));
                if (updatedProducts.length === 0) {
                    success('Все товары уже добавлены в группу.');
                    return;
                }
                setProducts(updatedProducts);
                setAddProductsToGroupFlag(true);
                setIsProductsModalOpen(true);
            } else {
                error('Произошла ошибка. Повторите позже.')
            }
        });
    };

    const toggleExpand = (groupID) => {
        if (currentGroupID === groupID) {
            setCurrentGroupID('');
            return;
        }
        setIsToggleLoading(groupID);
        api.viewGroup(groupID).then(r => {
            setIsToggleLoading('');
            if (r?.data) {
                console.log(r.data)
                setCurrentProducts(r.data?.products ? r.data.products : []);
                setCurrentLabels(r.data?.labels);
                setCurrentGroupID(groupID);
                setCurrentDiscount(r.data?.discount);
            } else {
                error('Произошла ошибка.')
            }
        });
    };

    useEffect(() => {
        api.getGroups().then(r => {
            if (r?.data) {
                setGroups(r.data);
            } else {
                error('Произошла ошибка при загрузке списка групп товаров');
            }
        });

        api.getLabels().then(r => {
            if (r?.data) {
                setLabelOptions(r.data);
            } else {
                error('При загрузке лейблов произошла ошибка');
            }
        });
    }, []);

    return (
        <>
            <Header title={"Управление группами товаров"} />
            <div className={styles.container}>
                <div className={styles.productGroups}>
                    <h1 className={styles.title}>Управление группами товаров</h1>
                    {groups.length > 0 && groups.map(group => (
                        <ProductGroup key={group.id} groupID={group.id} groupName={group.name} productsCount={group.products_number} labelOptions={labelOptions} deleteGroup={deleteProductsGroup} addProducts={openProductsModal} products={currentProducts} discountValue={currentDiscount} setProducts={setCurrentProducts} isExpanded={currentGroupID === group.id} toggleExpand={toggleExpand} isToggleLoading={isToggleLoading === group.id} labels={currentLabels} />
                    ))}
                </div>
                <AddGroupButton onClick={() => setIsNameModalOpen(prev => !prev)} />
            </div>
            <GroupNameModal isOpen={isNameModalOpen} onClose={closeNameModal} onContinue={continueNameModal} newGroupName={newGroupName} setNewGroupName={setNewGroupName} isProductsLoading={isProductsLoading} />
            <ProductsChoiceModal isOpen={isProductsModalOpen} onClose={closeProductsModal} onContinue={addProductsToGroupFlag ? updateProductsInGroup : createProductsGroup} products={products} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} />
            <Footer />
            {isLoading && <Loading />}
        </>
    )
}