import { error, success } from "../../../components/notification";
import { fixedCharacteristicsAPI, productAPI, productPhotosAPI } from "../api/api";

export function loadProduct(productID) {
    productAPI.get(productID).then(r => { })
}

function countPhotos(data) {
    let count = 0;

    if (Array.isArray(data)) {
        data.forEach(item => {
            count += countPhotos(item);
        });

    } else if (typeof data === 'object' && data !== null) {
        if (data.hasOwnProperty('photos')) {
            count += data.photos.length;
        }
        Object.values(data).forEach(value => {
            count += countPhotos(value);
        });
    }
    return count;
}

// Загрузка фото на сервер Yandex и обновление состояния
export async function uploadCardPhotos(productData, setIsLoading) {

    const photosCount = countPhotos(productData);

    const basePhotos = [...productData.base_combo.photos];
    const photosNumber = basePhotos.length;

    const addBasePhoto = (photoObject, oldCardPhotoUrl) => {
        productData.base_combo.photos = [...productData.base_combo.photos.filter(item => item.url !== oldCardPhotoUrl), photoObject];
    }

    if (photosCount > 0) {
        setIsLoading(true);
    }

    let counter = 0;
    for (var i = 0; i < photosNumber; i++) {
        if (!!basePhotos[i].file) {
            const cardPhoto = basePhotos[i].file;
            const oldCardPhotoUrl = basePhotos[i].url;
            const formdata = new FormData();
            formdata.append("image", cardPhoto);

            // eslint-disable-next-line no-loop-func
            await productPhotosAPI.uploadAndOptimizeImg(formdata).then((photoR) => {
                counter += 1;
                if (productData.base_combo.id === 'new') {
                    addBasePhoto({ url: photoR.data.url }, oldCardPhotoUrl);
                } else {
                    addBasePhoto({ url: photoR.data.url, combo: productData.base_combo.id }, oldCardPhotoUrl);
                }
            });
        } else { counter += 1; }
    }

    const addComboPhoto = (photoObject, oldCardPhotoUrl, comboIndex) => {
        productData.combos[comboIndex].photos = [...productData.combos[comboIndex].photos.filter(item => item.url !== oldCardPhotoUrl), photoObject];
    }

    for (let j = 0; j < productData.combos.length; j++) {
        const comboPhotos = [...productData.combos[j].photos];
        const comboPhotosNumber = productData.combos[j].photos.length;

        for (let i = 0; i < comboPhotosNumber; i++) {
            if (!!comboPhotos[i].file) {
                const cardPhoto = comboPhotos[i].file;
                const oldCardPhotoUrl = comboPhotos[i].url;
                const formdata = new FormData();
                formdata.append("image", cardPhoto);

                // eslint-disable-next-line no-loop-func
                await productPhotosAPI.uploadAndOptimizeImg(formdata).then((photoR) => {
                    counter += 1;
                    if (productData.combos[j].id === 'new') {
                        addComboPhoto({ url: photoR.data.url }, oldCardPhotoUrl, j);
                    } else {
                        addComboPhoto({ url: photoR.data.url, combo: productData.combos[j].id }, oldCardPhotoUrl, j);
                    }
                });
            } else { counter += 1; }
        }
    };

    if (counter >= photosCount) {
        setIsLoading(false);
    }
};

// Загрузка фото и продукта на сервер
export async function uploadProductData(productData, setIsLoading, nav, setLoadingParams) {

    const photosCount = countPhotos(productData);

    const basePhotos = [...productData.base_combo.photos];
    const photosNumber = basePhotos.length;
    setLoadingParams(prev => ({ ...prev, count: 1, size: photosNumber }));

    const addBasePhoto = (photoObject, oldCardPhotoUrl) => {
        productData.base_combo.photos = [...productData.base_combo.photos.filter(item => item.url !== oldCardPhotoUrl), photoObject];
    }

    if (photosCount > 0) {
        setIsLoading(true);
    }

    let counter = 0;
    for (var i = 0; i < photosNumber; i++) {
        if (!!basePhotos[i].file) {
            const cardPhoto = basePhotos[i].file;
            const oldCardPhotoUrl = basePhotos[i].url;
            const formdata = new FormData();
            formdata.append("image", cardPhoto);

            // eslint-disable-next-line no-loop-func
            await productPhotosAPI.uploadAndOptimizeImg(formdata).then((photoR) => {
                counter += 1;
                setLoadingParams(prev => ({ count: counter + 1, size: photosNumber }));
                if (photoR.data) {
                    if (productData.base_combo.id === 'new') {
                        addBasePhoto({ url: photoR.data.url }, oldCardPhotoUrl);
                    } else {
                        addBasePhoto({ url: photoR.data.url, combo: productData.base_combo.id }, oldCardPhotoUrl);
                    }
                } else {
                    error(`Произошла ошибка при загрузке файла ${cardPhoto.name}`);
                }
            }).catch((e) => { });
        } else { counter += 1; }
    }

    const addComboPhoto = (photoObject, oldCardPhotoUrl, comboIndex) => {
        productData.combos[comboIndex].photos = [...productData.combos[comboIndex].photos.filter(item => item.url !== oldCardPhotoUrl), photoObject];
    }

    for (let j = 0; j < productData.combos.length; j++) {
        const comboPhotos = [...productData.combos[j].photos];
        const comboPhotosNumber = productData.combos[j].photos.length;

        for (let i = 0; i < comboPhotosNumber; i++) {
            if (!!comboPhotos[i].file) {
                const cardPhoto = comboPhotos[i].file;
                const oldCardPhotoUrl = comboPhotos[i].url;
                const formdata = new FormData();
                formdata.append("image", cardPhoto);

                // eslint-disable-next-line no-loop-func
                await productPhotosAPI.uploadAndOptimizeImg(formdata).then((photoR) => {
                    counter += 1;
                    if (productData.combos[j].id === 'new') {
                        addComboPhoto({ url: photoR.data.url }, oldCardPhotoUrl, j);
                    } else {
                        addComboPhoto({ url: photoR.data.url, combo: productData.combos[j].id }, oldCardPhotoUrl, j);
                    }
                });
            } else { counter += 1; }
        }
    };

    if (counter >= photosCount) {
        setLoadingParams({ count: null, size: null });
        setIsLoading(false);
        if (productData.id === 'new') {
            await productAPI.create(productData).then((r) => {
                nav(`/product/${r.data.id}`);
            }).catch(() => { error('При создании товара произошла ошибка!'); nav(`/positions`); });
        } else {
            if (productData.combos.length === 0) {
                productData.is_variative = false;
            }

            await productAPI.update(productData.id, productData).then((updateR) => {
                if (updateR?.data) {
                    success('Данные обновлены!');
                    if (productData.combos.length > 0) window.location.reload();
                }
            });
        }
    }
};

// Удаление фото из базовой комбинации по его индексу
export async function removeBaseComboPhoto(index, productData, setProductData) {
    const newPhotos = [...productData.base_combo.photos];
    const removedPhoto = newPhotos.splice(index, 1)[0];
    if (removedPhoto.id) {
        try {
            await productPhotosAPI.delete(removedPhoto.id);
        } catch (error) {
            console.error('Error deleting photo:', error);
        }
    }
    setProductData(prevState => ({
        ...prevState,
        base_combo: {
            ...prevState.base_combo,
            photos: newPhotos
        }
    }));
};

// Удаление фото из комбинации по его индексу
export async function removePhotoFromCombo(comboIndex, photoIndex, productData, setProductData) {
    const newCombos = [...productData.combos];
    const combo = { ...newCombos[comboIndex] };
    const newPhotos = [...combo.photos];
    const removedPhoto = newPhotos.splice(photoIndex, 1)[0];
    if (removedPhoto.id) {
        try {
            await productPhotosAPI.delete(removedPhoto.id);
        } catch (error) {
            console.error('Error deleting photo:', error);
        }
    }

    setProductData(prevState => {
        combo.photos = newPhotos;
        newCombos[comboIndex] = combo;
        return {
            ...prevState,
            combos: newCombos
        };
    });
};

// Функция для добавления объекта к массиву fixed_characteristics
export async function addFixedCharacteristic(newCharacteristic, setProductData) {
    if (newCharacteristic.product !== 'new') {
        await fixedCharacteristicsAPI.create(newCharacteristic).then(r => {
            newCharacteristic.id = r.data.id;
            setProductData(prevState => ({
                ...prevState,
                fixed_characteristics: [...prevState.fixed_characteristics, newCharacteristic]
            }));
        });
    } else {
        setProductData(prevState => ({
            ...prevState,
            fixed_characteristics: [...prevState.fixed_characteristics, newCharacteristic]
        }));
    }
};

// Функция для удаления объекта из массива fixed_characteristics
export async function removeFixedCharacteristic(index, productData, setProductData) {
    const newCharacteristics = [...productData.fixed_characteristics];
    const removedCharacteristic = newCharacteristics.splice(index, 1)[0];
    if (removedCharacteristic.id) {
        try {
            await fixedCharacteristicsAPI.delete(removedCharacteristic.id);
        } catch (error) {
            console.error('Error deleting characteristic:', error);
        }
    }

    setProductData(prevState => {
        return {
            ...prevState,
            fixed_characteristics: newCharacteristics
        };
    });
};

// Удаление карточки из мультикарточки по индексу
export async function removeCard(comboIndex, productData, setProductData) {
    const newCombos = [...productData.combos];
    const removedCombo = newCombos.splice(comboIndex, 1)[0];

    if (removedCombo.id) {
        try {
            await productAPI.deleteCard(removedCombo.id);
        } catch (error) {
            console.error('Error deleting combo:', error);
        }
    }

    setProductData(prevState => {
        return {
            ...prevState,
            combos: newCombos,
            is_variative: newCombos.length === 0 ? false : prevState.is_variative
        };
    });
};

export function addCardCombo(var1Value, var2Value, productData, setProductData) {
    const comboExists = productData.combos.some(combo => combo.var1_value === var1Value && combo.var2_value === var2Value);

    if (!comboExists) {
        const newCombo = {
            ...productData.base_combo,
            photos: productData.base_combo.photos.map(({ url }) => ({ url })),
            var1_value: var1Value,
            var2_value: var2Value,
            base: false
        };
        delete newCombo.id;

        setProductData(prevState => ({
            ...prevState,
            combos: [...prevState.combos, newCombo]
        }));
    }
};